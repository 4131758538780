<template>
  <v-container fluid>
    <v-card
    >
      <v-card-title>
        {{$vuetify.lang.t('$vuetify.patient.sign')}}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="4"
          >
            <v-subheader
              class="pl-0"
            >
              {{$vuetify.lang.t('$vuetify.patient.informtion')}} :
            </v-subheader>
            <v-list
              v-if="signPatient"
              class="transparent"
            >
              <v-list-item
              >
                <v-list-item-subtitle>
                  {{$vuetify.lang.t('$vuetify.patient.name')}} :
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ signPatient.patientName }}
                </v-list-item-title>

              </v-list-item>

              <v-list-item
              >
                <v-list-item-subtitle>
                  {{$vuetify.lang.t('$vuetify.common.identityCard')}} :
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ signPatient.credentialNum }}
                </v-list-item-title>

              </v-list-item>

              <v-list-item
              >
                <v-list-item-subtitle>
                  {{$vuetify.lang.t('$vuetify.common.address')}} :
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ signPatient.address }}
                </v-list-item-title>

              </v-list-item>

            </v-list>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <v-form
              ref="form"
            >
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="contractName"
                    :label="$vuetify.lang.t('$vuetify.contract.label')"
                    :rules="rules.contract"
                    append-icon="mdi-magnify"
                    readonly
                    @click="handleSearchClick"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="institutionHomeDoctor"
                    :label="$vuetify.lang.t('$vuetify.patient.institutionAndDoctor')"
                    :rules="rules.institutionHomeDoctor"
                    append-icon="mdi-magnify"
                    readonly
                    @click="handleInstitutionDoctorClick"
                    @click:clear="handleInstitutionDoctorClearClick"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info darken-1"
          @click="handleCancel"
        >
           {{$vuetify.lang.t('$vuetify.common.cancel')}}
        </v-btn>
        <v-btn
          color="primary darken-1"
          @click="handleSubmit"
        >
           {{$vuetify.lang.t('$vuetify.common.submit')}}
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-model="dialogContractList"
        persistent
        max-width="800px"
      >
        <contract-list
          @submit="handleContractSubmit"
          @cancel="handleContractCancel"
        />
      </v-dialog>

      <v-dialog
        v-model="dialogInstitutionDoctor"
        persistent
        max-width="800px"
      >
        <institution-doctor
          @submit="handleInstitutionDoctorSubmit"
          @cancel="handleInstitutionDoctorCancel"
        />
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      ContractList: () => import('./contract'),
      InstitutionDoctor: () => import('./InstitutionDoctor')
    },
    props: {
      id: {
        type: String,
        default: () => null
      }
    },
    data() {
      return {
        dialogContractList: false,
        dialogInstitutionDoctor: false,
        contractSelected: null,
        institutionSelected: null,
        homeDoctorSelected: null,
        signPatient: null,
        rules: {
          contract: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.patient.name')])
          ],
          institutionHomeDoctor: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.patient.institutionAndDoctor')])
          ]
        }
      }
    },

    computed: {
      ...mapGetters({
        currentPatient: 'patient/currentPatient'
      }),

      contractName () {
        return this.contractSelected ? this.contractSelected.contractName : null
      },

      institutionHomeDoctor () {
        return this.institutionSelected && this.homeDoctorSelected ? `${ this.institutionSelected.showName } - ${ this.homeDoctorSelected.realName }` : null
      }
    },

    created () {
      this.initialize()
    },

    methods: {

      ...mapActions({
        patientShow: 'patient/show',
        patientSign: 'patient/sign'
      }),

      initialize () {
        if (this.id) {
          this.patientShow(this.id).then( () => {
            this.signPatient = this.currentPatient
          })
        }
      },

      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.$nextTick ( () => {
          this.$refs.form.resetValidation()
        })

      },

      handleCancel() {
        this.$router.go(-1)
      },

      handleSubmit() {
        if (this.id && this.$refs.form.validate()) {
          let data = {}
          data.contractId = this.contractSelected.id
          data.institutionId = this.institutionSelected.id
          data.doctorId = this.homeDoctorSelected.id
          this.patientSign( { id:  this.id, data } ).then( () => {
            this.$router.push( { name: 'patientList' } )
          })
        } else {
          return false
        }
      },

      handleSearchClick () {
        this.dialogContractList = true
      },

      handleClearClick () {

      },

      handleContractSubmit (data) {
        this.contractSelected = data
        this.dialogContractList = false
      },

      handleContractCancel () {
        this.dialogContractList = false
      },

      handleInstitutionDoctorSubmit ( data ) {
        this.institutionSelected = data.institution
        this.homeDoctorSelected = data.homeDoctor
        this.dialogInstitutionDoctor = false
      },

      handleInstitutionDoctorCancel () {
        this.dialogInstitutionDoctor = false
      },

      handleInstitutionDoctorClick () {
        this.dialogInstitutionDoctor =  true
      },

      handleInstitutionDoctorClearClick () {

      }

    }
  }
</script>
